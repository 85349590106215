// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Hamburger {
  display: none;
}

.BurgerButtons {
  width: 200px;
  height: 60px;
}

@media screen and (max-width: 1023px) {
  #Hamburger {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/header/Hamburger.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;AAEF;;AAAA;EACE;IACE,aAAA;EAGF;AACF","sourcesContent":["#Hamburger {\n  display: none;\n}\n.BurgerButtons {\n  width: 200px;\n  height: 60px;\n}\n@media screen and (max-width: 1023px) {\n  #Hamburger {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
